
.mask-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    /*background: rgba(255, 255, 255, .5);*/
    display: flex;
    align-items: center;
    justify-content: center;
    .prompt {
        color: #fff;
        font-size: 30px;
        .iconfont {
            font-size: 30px;
            margin-right: 10px;
        }
    }
}
.storeDecorate-container {
    background: #fff;
    min-height: calc(100vh - 120px);
    box-sizing: border-box;
    padding: 30px 90px 50px;
    display: flex;
    flex-direction: column;
    .storeSign-upload {
        .storeSign-img {
            width: 100%;
            height: 0;
            padding-bottom: 6.25%;
            position: relative;
            background: #fafafa;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
    .storeSign-prompt {
        color: #999;
        margin-top: 20px;
        min-height: 45px;
        border: 1px solid #DCDCDC;
        line-height: 21px;
        padding: 10px 20px;
        box-sizing: border-box;
        .iconfont {
            color: #FF0000;
        }
        .title {
            color: #333;
            margin: 0 20px;
        }
    }
    .storeSign-btn {
        text-align: center;
    }
}
